import { useEffect, useState, useMemo } from "react";
import allCourses from "./courses.json";
import tagOptions from "./tagOptions.json";

export default function Courses() {
    const [tag, setTag] = useState("");
    const [searchCourse, setSearchCourse] = useState("");

    const courses = useMemo(() => {
        if (tag === "") {
            if (searchCourse === "") {
                return allCourses;
            } else {
                return allCourses.filter((course) => {
                    const searchFields =
                        `${course.title.toLowerCase()}`;
                    return searchFields.includes(searchCourse.toLowerCase());
                });
            }
        }
        return allCourses.filter((course) => {
            const courseTag = course.tags.map((val) => val);
            return courseTag.includes(tag);
        });
    }, [tag, searchCourse]);

    useEffect(() => {
        if (searchCourse !== "") {
            setTag("");
        }
    }, [searchCourse]);

    return (
        <div className="bg-inherit" id="courses">
            <div className="flex justify-center p-10 md:p-20">
                <div>
                    <h1 className="text-3xl font-bold text-center mb-7 md:mb-14 md:text-5xl text-gray-50">Выбрать скидку:</h1>
                    <div>
                        <div className="flex justify-center mb-3 md:mb-7">
                            <form className="flex flex-wrap items-center justify-center gap-5 mb-7">
                                <input
                                    className="rounded-md p-[4px]"
                                    name="searchCourse"
                                    placeholder="Найти курс"
                                    value={searchCourse}
                                    onChange={(e) => setSearchCourse(e.target.value)}
                                />
                                <select
                                    className="rounded-md px-[4px]"
                                    value={tag}
                                    onChange={(e) => setTag(e.target.value)}
                                >
                                    {tagOptions.map((option, i) => {
                                        return (
                                            <option className="py-2" value={option.value} key={i}>
                                                {option.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </form>
                        </div>
                        <div className="flex flex-wrap justify-center w-full gap-10">
                            {courses.map((course, index) => {
                                return (
                                    <div key={index} className="p-6 bg-white rounded-lg max-w-[400px] flex flex-col justify-between space-y-4">
                                        <div>

                                            <div className="mb-4">
                                                <img className="rounded-lg" src={course.img} alt="" />
                                            </div>

                                            <div>
                                                <p className="mb-2 text-2xl font-semibold leading-6">{course.title}</p>
                                                <p className="mb-2">{course.description}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="text-lg">
                                                <p className="font-bold">Ведущий: <span className="font-normal">{course.tutor}</span></p>
                                                <p className="text-xl font-bold">Цена: <span className="font-normal line-through">{course.old_price} р.</span> <span>{course.price} р.</span></p>
                                            </div>
                                            <div className="mt-2 flex flex-wrap gap-2 w-[300px] mb-6">
                                                {course.tags.map((tag, index) => {
                                                    return (<span key={index} className="px-2 py-1 text-sm font-semibold bg-violet-100 rounded-xl">{tag}</span>)
                                                })}
                                            </div>
                                            <div>
                                                <a className="bg-gradient-to-r from-[#737fe6] to-[#dd7c7c] p-2 text-gray-100 text-lg rounded-lg" href={course.link} target="_blank">Воспользоваться скидкой</a>
                                            </div>
                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
