import React from 'react'
import Discount2 from './img/disc2.png'

export default function Hero() {
    return (
        <div className='relative overflow-hidden bg-inherit'>
            <div className='flex justify-center p-10 md:px-20 md:pt-28 md:pb-20'>
                <div className='max-w-[1240px]'>
                    <div className='relative z-10'>
                        <div className='absolute -z-1 w-[500px] top-18 -left-[85px] opacity-25 block md:hidden'>
                            <img src={Discount2} alt="" />
                        </div>
                    </div>
                    <div className='relative z-10 flex flex-col items-center justify-center space-x-5 md:flex-row'>
                        <div>
                            <h1 className='mb-4 text-5xl font-extrabold text-transparent lg:text-7xl bg-clip-text bg-gradient-to-r from-[#737fe6] to-[#dd7c7c]'>Черная Пятница <br /> в «МСМК»</h1>
                            <h1 className='text-2xl font-extrabold text-transparent bg-gray-100 lg:text-4xl mb-7 bg-clip-text'>11 наших лучших продуктов со скидкой 50%</h1>
                            <div className='text-gray-100'>
                                <div className='mb-10 text-lg text-white md:text-xl'>
                                    <ul className='list-disc pl-7 mb-7'>
                                        <li>Как помочь зависимому близкому?</li>
                                        <li>Как изменить свою жизнь?</li>
                                        <li>Как изменить отношения в семье?</li>
                                        <li>Как перестать страдать и стать счастливой?</li>
                                    </ul>
                                    <p>Получите ответы на эти и многие другие вопросы из наших лучших курсов по самой выгодной цене.</p>
                                </div>
                            </div>
                            <a href='#courses' className='bg-gradient-to-r from-[#737fe6] to-[#dd7c7c] px-4 py-3 text-gray-100 text-xl lg:text-2xl rounded-lg shadow-lg hover:shadow-xl shadow-[#dd7c7c]/30 hover:shadow-[#dd7c7c]/30'>Выбрать курс</a>
                        </div>
                        <div className='hidden w-[400px] md:block'>
                            <img src={Discount2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
